import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from './guards/logged-in.guard';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'contents',
    loadChildren: () => import('./pages/contents/contents.module').then(m => m.ContentsModule)
  },
  {
    path: 'meta-setting',
    loadChildren: () => import('./pages/meta-setting/meta-setting.module').then(m => m.MetaSettingModule)
  },
  {
    path: 'assets-setting',
    loadChildren: () => import('./pages/assets-setting/assets-setting.module').then(m => m.AssetsSettingModule)
  },
  
  {
    path: 'tag-management',
    loadChildren: () => import('./pages/tag-management/tag-management.module').then(m => m.TagManagementModule)
  },
  {
    path: 'feed-management',
    loadChildren: () => import('./pages/feed-management/feed-management.module').then(m => m.FeedManagementModule)
  },
  {
    path: 'widget-management',
    loadChildren: () => import('./pages/widget-management/widget-management.module').then(m => m.WidgetManagementModule)
  },
  
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }

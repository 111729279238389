import { pick, merge } from "lodash-es";

function setSavedState(state:any, localStorageKey: string){
    localStorage.setItem(localStorageKey, JSON.stringify(state));
}

function getSavedState(localStorageKey: string){
    return JSON.parse(localStorage.getItem(localStorageKey) as string);
}

const stateKeys = [
    'user',
    'userToken',
    'isLoading',
    'basicInfo',
    'deviceDetails'
]

const localStorageKey = '__app_storage__';

export function storageMetaReducer(reducer: any){
    let onInit = true;
    return function(state: any, action: any): any {
        const nextState = reducer(state, action);

        if(onInit){
            onInit = false;
            const savedState = getSavedState(localStorageKey);

            return merge(nextState, savedState);
        }

        const stateToSave = pick(nextState, stateKeys);
        setSavedState(stateToSave, localStorageKey);

        return nextState;
    }
}

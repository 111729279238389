import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromActions from './app.actions';
import { BehaviorSubject,Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { AppState , } from './app.reducer';
import { appQuery } from "./app.selector";




@Injectable({
  providedIn: 'root'
})
export class AppStoreFacadeService {
  user$ = this._store.select(appQuery.getUser).pipe(shareReplay(1));
  deviceDetails$ = this._store.select(appQuery.getDeviceDetails).pipe(shareReplay(1));
  isLoading$ = this._store.select(appQuery.getLoading).pipe(shareReplay(1));
  basicInfo$ = this._store.select(appQuery.getBasicInfo).pipe(shareReplay(1));

  constructor(private _store: Store<AppState>) {}

  updateUser(user: any): void {
    this._store.dispatch(new fromActions.UpdateUser(user));
  }
  
  updateBasicInfo(basicInfo: any): void {
    this._store.dispatch(new fromActions.UpdateBasicInfo(basicInfo));
  }

  updateLoading(visibility: boolean): void {
    this._store.dispatch(new fromActions.UpdateLoaderVisibility(visibility));
  }

  updateDeviceDetails(deviceDetails: any): void {
    this._store.dispatch(new fromActions.UpdateDeviceDetails(deviceDetails));
  }
}